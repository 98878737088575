.verticalSimpleBarChartContainer {
  width: 100%;
  height: 100%;
  margin-top: 2em;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-bottom: 20px;
  min-width: 260px;

  flex-grow: 1;
}

.verticalSimpleBarChartVerticalContainer {
  width: 100%;
  height: 100%;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
}

.verticalSimpleBarChartLeftContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.verticalSimpleBarChartLeftContainer > span {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  position: relative;

  color: #45525e;
}

.verticalSimpleBarChartGridContainer {
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
  margin-left: 20px;
  position: relative;
}

.verticalSimpleBarChartLine {
  height: 16px;
  border-top: 1px dashed #c7c7c7;
}

.verticalSimpleBarChartLineBottom {
  height: 16px;
  border-bottom: 1px dashed #c7c7c7;
}

.verticalSimpleBarChartDrawContainer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.verticalSimpleBarChartBar {
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;

  animation-name: scaleUp;
  animation-timing-function: linear;
  transform-origin: bottom;
  animation-fill-mode: backwards;
  min-height: 0px;
  flex-basis: 0;
}

.verticalSimpleBarChartBar > span {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  text-align: center;

  color: #ffffff;
}
