.mapChartContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  align-items: center;
  width: 0px;
}

.mapChartContainer > p {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
  text-transform: uppercase;

  color: #162235;
}

.mapContainer {
  margin-top: 30px;
  position: relative;
}

.mapLegendContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 30px;
  top: -25px;
}

.mapLegendContainer > span {
  font-family: Source Sans Pro;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;

  align-items: center;
  text-align: center;

  color: #233142;
}

.mapPin {
  width: 24px;
  height: 24px;
}
