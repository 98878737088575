.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  align-self: stretch;
  margin-top: 2em;
  margin-right: 4em;
  z-index: 100;
}

.buttonShadow {
  box-shadow: 0px 2px 8px rgba(47, 118, 41, 0.2);
}
