.indicatorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 116px;
  min-width: 100px;
  flex-basis: 20%;
  flex-shrink: 0;

  margin-top: 2em;
  margin-bottom: 2em;
  position: relative;
}

.indicatorContainerLarge {
  width: 152px;
  height: 238px;
  align-items: center;
  flex-basis: auto;
}

.indicatorImageLarge {
  width: 152px;
  height: 152px;
}

.indicatorImageSmall {
  width: 27px;
  height: 27px;
}

.indicatorContainer > p {
  margin-top: 12px;
  margin-bottom: 0;
  text-align: center;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.indicatorContainerLarge > p {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  text-align: center;

  color: #2e2e2e;
}

.indicatorPercentage {
  border-radius: 8px;
  color: white !important;
  font-weight: normal !important;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 76px;
  align-self: center;
}
