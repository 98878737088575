.loaderContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100vw - 4px);
  height: calc(100vh - 4px);

  justify-content: center;
  align-items: center;
  display: flex;
  overflow-y: scroll;
}

.slowOpacity-enter {
  transform: translate3d(0%, 100%, 0);
  opacity: 1;
}
.slowOpacity-enter-active {
  transform: translate3d(0%, 0%, 0);
  z-index: -100 !important;
  opacity: 1;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}
.slowOpacity-exit {
  transform: translate3d(0%, 0%, 0);
  opacity: 1;
}
.slowOpacity-exit-active {
  transform: translate3d(0%, -100%, 0);
  opacity: 1;
  z-index: -100 !important;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}

.slowOpacity-reverse-enter {
  transform: translate3d(0%, -100%, 0);
  opacity: 1;
}
.slowOpacity-reverse-enter-active {
  transform: translate3d(0%, 0%, 0);
  z-index: -100 !important;
  opacity: 1;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}
.slowOpacity-reverse-exit {
  transform: translate3d(0%, 0%, 0);
  opacity: 1;
}
.slowOpacity-reverse-exit-active {
  transform: translate3d(0%, 100%, 0);
  opacity: 1;
  z-index: -100 !important;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}
.opacity-enter {
  opacity: 0;
}

.opacity-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}

.opacity-exit {
  opacity: 1;
}

.opacity-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}
