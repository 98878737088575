.blur {
  filter: blur(2px);
  -webkit-filter: blur(2px);
  pointer-events: none;
}

.blurLarge {
  filter: blur(10px);
  -webkit-filter: blur(10px);
  pointer-events: none;
}

.blurWhite {
  background-color: white;
}

.highlight {
  padding-top: 2em;
  padding-bottom: 2em;
  box-shadow: 0px 0px 28px rgba(61, 62, 61, 0.4);
}

.highlightNoPadding {
  box-shadow: 0px 0px 28px rgba(61, 62, 61, 0.4);
}

.highlightIcon {
  width: 75px;
}

#highlightContainer {
  animation: slide;
  display: flex;
  flex-direction: row;

  position: absolute;
  background-color: white;
  border-radius: 32px;
  align-items: center;
  padding: 20px;
  box-shadow: 0px 0px 16px rgba(44, 130, 63, 0.4);
  z-index: 1000;
}

#highlightDescription {
  max-width: 700px;
  margin-left: 15px;
  margin-right: 15px;
  font-family: Source Sans Pro;
}

.highlightSkipButton {
  margin-right: 10px;
}

@keyframes slide-up-fade-in {
  from {
    opacity: 0;
    transform: translate(-50%, 100%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@-webkit-keyframes slide-up-fade-in {
  from {
    opacity: 0;
    transform: translate(-50%, 100%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

#highlightIntroContainer {
  position: absolute;
  z-index: 2000;
  padding: 40px;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;

  background-color: white;
  border-radius: 32px;
  align-items: center;
  padding: 60px;
  box-shadow: 0px 0px 16px rgba(44, 130, 63, 0.4);
  max-width: 500px;

  animation-iteration-count: 1;
  animation-fill-mode: backwards;
  animation: slide-up-fade-in ease 1s;
}

#highlightIntroTitle {
  font-family: Source Sans Pro;
  font-weight: 600;
  font-size: 40px;
  line-height: 52px;
}

#highlightIntro {
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin-top: 40px;
}

#highlightImage {
  margin-top: 40px;
  margin-bottom: 40px;
}

.highlighIntroButton {
  width: 200px;
  margin-top: 30px;
  height: 60px;
}
