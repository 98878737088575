@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.sideMenu {
  width: 72px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 1em;
  position: relative;
  padding-bottom: 3em;
}

.background {
  background: #7ad077;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 24px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.backgroundTop {
  top: 0;
}

.backgroundBottom {
  bottom: 0;
}

.logo {
  background: #7ad077;
  margin-bottom: 5em;
  z-index: 100;
}

.item {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.selected {
  background-color: #7ad077;
}

.unselected {
  background-color: white;
}

.selectedContainer {
  height: 101px;
  align-self: stretch;
  align-items: center;
  position: relative;
  justify-content: center;
  display: flex;
}

.unselectedContainer {
  width: 72px;
  height: 101px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rectangle {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 40px;
  background-color: white;
}

.item img {
  width: 22px;
  height: 22px;
}

.selectedCircle {
  border-radius: 50%;
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 68px;
  height: 68px;
}

.white {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}

.menuContainer {
  display: flex;
  align-self: stretch;
  align-items: stretch;
  background: #ffffff;
  box-shadow: 4px 0px 16px rgba(10, 41, 8, 0.08);

  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 300;
}

.textItemsContainer {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  width: 180px;
}

.textItems {
  display: flex;
  flex-direction: column;

  position: relative;
  align-items: flex-start;
  margin-left: 1em;
  margin-top: 12em;
  margin-right: 10px;
}

.textItems span:not(.textItemSelected) {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;

  color: #45525e;
}

.textItems span:not(.textItemSelected):hover {
  transform: scale(1.2);
  transform-origin: 0% 100%;
  cursor: pointer;
}

.textItemSelected {
  color: #4fb654 !important;
  font-size: 16px;
  padding-bottom: 2px;
  border-bottom: 1px solid #4fb654;
  align-self: flex-start;
  margin-bottom: 1em;
  padding-right: 3px;
  text-transform: uppercase;
}

.indentSmall {
  margin-left: 1em !important;
}

.indentLarge {
  margin-left: 2em !important;
  margin-top: -1em !important;
}

.menuItemsNotSelected {
  margin-bottom: 1em;
}

#logoutContainer {
  position: absolute;
  top: 30px;
  left: 90px;
  height: 0px;
  transition: all 0.3s ease-in-out;

  display: flex;
  box-shadow: 0px 0px 12px rgba(61, 62, 61, 0.1);
  background-color: white;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}

#logoutContainer > span {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  padding-left: 2em;
  padding-right: 2em;
}

#settings:hover + #logoutContainer,
#logoutContainer:hover {
  height: 60px;
}

#settings:hover + #logoutContainer > span,
#logoutContainer:hover > span {
  opacity: 1;
}

#logoutContainer,
#settings {
  cursor: pointer;
  z-index: 1;
}

#settings:hover {
  opacity: 0.7;
}

#logoutContainer > span:hover {
  transform: scale(1.2);
}

.selectedIconBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media screen and (min-width: 1920px) {
  .textItemSelected {
    font-size: 18px;
  }

  .textItems span:not(.textItemSelected) {
    font-size: 16px !important;
  }
}

span,
p {
  font-family: Source Sans Pro;
  color: #45545f;
}

.text {
  font-family: Source Sans Pro !important;
  color: #45545f;
  fill: #45545f;
}

.container {
  display: flex;
  height: 100vh;
  align-items: flex-start;
  flex: 1 1 auto;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  flex-grow: 1;
  align-items: flex-start;
  margin-left: 252px;
  margin-bottom: 2em;
  transition: margin-left 3s;
}

.header {
  display: flex;
  justify-content: flex-end;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.06);
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 20px;
  align-self: stretch;
}

.titleLabel {
  margin-left: 2em;
  margin-top: 1em;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;

  color: #7f909e;
}

.chartContainer {
  box-shadow: 0px 0px 12px rgba(61, 62, 61, 0.1);
  border-radius: 12px;
  margin-left: 2em;
  margin-top: 10px;
  margin-right: 2em;
  display: flex;
  align-self: stretch;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;

  position: relative;
  padding-left: 2em;
  padding-right: 1em;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;
}

.chartContainer.home {
  padding-left: 2em;
}

.chartContainer.home.first {
  padding-left: calc(2em + 7px);
}

.chartContainer.costs {
  padding-left: 0.5em;
  padding-top: 1em;
  padding-bottom: 1em;
}

.chartContainer.lqi {
  padding-left: 1em;
  justify-content: space-between;
}

.chartContainer.bottomRow {
  justify-content: space-between;
  padding-left: 2em;
}

.chartContainer.verticalPadding {
  padding-top: 2em;
  padding-bottom: 2em;
}

.chartContainer.extraPadding {
  padding-left: 4em;
  padding-right: 4em;
}

.chartContainer.goals {
  padding-left: 6em;
  padding-right: 6em;
}

@media screen and (min-width: 1920px) {
  .chartContainer {
    padding-left: 4em;
    padding-right: 4em;
  }

  .chartContainer.home {
    padding-left: 4em;
  }

  .chartContainer.home.first {
    padding-left: calc(4em + 7px);
  }

  .chartContainer.costs {
    padding-left: 2em;
  }

  .chartContainer.lqi {
    padding-left: 3em;
    padding-right: 3em;
  }

  .chartContainer.extraPadding {
    padding-left: 6em;
    padding-right: 6em;
  }

  .chartContainer.bottomRow {
    padding-left: 4em;
    padding-right: 4em;
  }

  .chartContainer.goals {
    padding-left: 8em;
    padding-right: 8em;
  }
}

.chartContainer.engagement {
  height: 330px;
  align-items: center;
}

.chartContainer.engagement.first {
  margin-bottom: 2em;
  min-height: 300px;
  max-height: 300px;
}

.verticalPadding {
  padding-top: 1em;
  padding-bottom: 1em;
}

.labelRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin-right: 2em;
}

.dropdownWelcomeContainer {
  margin-top: 2em;
}

.indicatorsRow {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  flex-wrap: wrap;
}

.currentScoresRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  margin-left: 2em;
}

#currentScoreRowContainer {
  flex-grow: 1;
  margin-left: 2em;
}

.chartColumnContainer {
  flex-grow: 1;
  flex-basis: 0;
  margin-right: 2em;
  margin-bottom: 2em;
  position: relative;
}

.verticalChartContainer {
  box-shadow: 0px 0px 12px rgba(61, 62, 61, 0.1);
  border-radius: 12px;
  margin-top: 10px;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  max-height: 450px;
  min-height: 450px;
  position: relative;
}

.verticalChartContainerLarge {
  min-height: 520px;
  max-height: 520px;
}

.columnLabel {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
  text-transform: uppercase;

  color: #162235;
  align-self: center;
}

.rowColumnChartContainer {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  justify-content: space-between;
  padding-left: 2em;
  flex-wrap: wrap;
}

.centerTopMargin {
  margin-top: 15px;
  align-self: center;
}

.circularChartRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 3em;
  margin-right: 1em;
}

.squareChartColumnContainer {
  margin-right: 2em;
  flex-basis: 0;
  flex-grow: 1;

  min-width: 350px;
  margin-bottom: 1em;
}

.squareChartContainer {
  box-shadow: 0px 0px 12px rgba(61, 62, 61, 0.1);
  border-radius: 12px;
  margin-top: 10px;
  padding: 1em;
  display: flex;
  flex-direction: column;
  height: 500px;
  align-items: center;

  position: relative;
}

.mediumVerticalChartContainer {
  box-shadow: 0px 0px 12px rgba(61, 62, 61, 0.1);
  border-radius: 12px;
  margin-top: 1em;
  padding: 2em;
  display: flex;
  flex-direction: column;
  height: 400px;
  align-items: stretch;
}

.largeChart {
  width: 10em !important;
  height: 10em !important;
}

.chartBottomLabel {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  text-align: center;

  color: #162235;
  margin-top: 30px;
}

.chartLabelContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  min-height: 0;
}

.largeChartSpan {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 52px;

  align-items: center;
  text-align: center;

  color: #233142;
  margin-left: 10px;
}

.largeChartSmallLabel {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  text-align: center;

  color: #7f909e;
  margin-top: 5px;
}

.largeArrowChartMonthLabel {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 4px !important;
  line-height: 24px;

  text-align: center;
  text-transform: uppercase;

  color: #7f909e;
}

.sourceSansProText {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  color: #233142;
}

.selectors {
  display: flex;
  flex-direction: row;
  margin-left: 2em;
  margin-top: 10px;
}

.selectorInactive {
  background: #eaf3f6;
  border-radius: 12px;
  margin-left: 10px;
  padding: 10px;
  padding-bottom: 20px;
}

.selectorInactive:hover {
  cursor: pointer;
  opacity: 0.7;
}

.selectorActive {
  background-color: white;
  border-radius: 12px;
  padding: 10px;
  padding-bottom: 20px;
  box-shadow: 0px 0px 12px rgba(61, 62, 61, 0.1);
  z-index: 1000;
  margin-left: 10px;
}

#whiteCover {
  background-color: white;
  margin-top: -10px;
  height: 15px;
  position: absolute;
  width: 100%;
  left: 0px;
  bottom: -10px;
  z-index: 100;
}

.speedChartContainer {
  min-width: 200px;
}

.speedChartContainer:hover svg {
  opacity: 0.7;
  cursor: pointer;
}

.scoreDetailsContainer {
  margin-right: 2em;
  margin-left: 2em;
  flex-basis: 50%;

  min-width: 350px;
  margin-bottom: 1em;
  box-shadow: 0px 0px 12px rgba(61, 62, 61, 0.1);
  border-radius: 12px;
  min-height: 400px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
}

.scoreDetailsRow {
  flex-direction: row;
}

.scoreDetailsButtonContainer {
  flex-basis: 0;
  flex-grow: 1;
  align-self: stretch;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  margin-left: 2em;
  margin-right: 2em;
  margin-top: 4em;
  margin-bottom: 4em;
}

.scoreDetailsButton {
  border-radius: 8px !important;
}

.speedChartLargeLabel {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 550;
  font-size: 28px;
  line-height: 52px;

  color: #334050;
  margin-bottom: 1em;
}

.scoreDetailsRowContainer {
  align-self: stretch;
  display: flex;
  margin-top: 10px;
}

.scoresFutureOutlookRow {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2em;
  padding-top: 2em;
  align-self: stretch;
}

.outlookCell {
  position: relative;
  width: 0px;
  flex-grow: 1;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.outlookContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
}

.outlookContainer > span {
  font-size: xx-large;
  margin-left: 5px;
  font-weight: bold;
}

.futureOutlook {
  color: black;
}

.recommendationsContainer {
  align-self: stretch;
  margin-left: 2em;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.arrowContainer {
  align-self: stretch;
  display: flex;
  justify-content: space-between;

  margin-left: 2em;
  margin-right: 2em;
}

.arrow {
  padding-top: 2em;
  padding-bottom: 2em;
}

.disabled {
  opacity: 0.3;
}

.arrow:not(.disabled):hover {
  opacity: 0.7;
  cursor: pointer;
}

.emojiMainContainer {
  align-self: center;
  box-shadow: 0px 0px 16px rgba(11, 34, 12, 0.1);
  border-radius: 32px;

  margin-top: 10px;
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 1em;
}

.emojiMainContainer.hidden {
  visibility: hidden;
}

.emojiMainContainer.active:hover {
  opacity: 0.7;
  cursor: pointer;
}

.emojiMain {
  font-size: 28px;
  margin-left: 1em;
  margin-right: 1em;
}

.emojiMain.active:hover {
  transform: scale(1.5, 1.5);
  cursor: pointer;
}

.emojiMain.emojiSelected {
  transform: scale(1.5, 1.5);
}

.speedChartIndicatorLabel {
  font-family: Source Sans Pro;
  font-size: 14px;
  color: #334050;
  align-self: flex-end;
}

.oneLine {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-align: center;
  -webkit-line-clamp: 1;
}

.fillChartCostsContainer {
  position: relative;
  flex: 1 0 auto;
  min-width: 235px;
  width: 0px;
}

.speedChart {
  min-width: 190px;
  position: relative;
}

.speedChart.showUpdated {
  cursor: pointer;
}

.speedIndicatorsContainer {
  display: flex;
  width: 7em;
  justify-content: space-between;
  position: absolute;
}

.lqiSecondRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.lqiThirdRow {
  display: flex;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.scoreButton {
  border-radius: 8px;
}

.scoreButtonText {
  font-size: 15px !important;
  font-weight: 500 !important;
}

@media screen and (min-width: 1920px) {
  .scoreButtonText {
    font-size: 18px !important;
  }
}

.speedChartLastUpdatedContainer {
  background-color: #4fb654;
  border-radius: 8px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 7em;
  height: 70px;
  width: 100px;
}

.speedChartLastUpdatedContainer span {
  color: white;
  font-size: 14px;
}

.engagementRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
}

#speedChartLargeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#healthStatusBottomRightSpan {
  position: absolute;
  bottom: 10px;
  right: 30px;
  font-size: x-small;
  color: gray;
}

.hoverable:hover {
  opacity: 0.7;
  cursor: pointer;
}

.arrowChartSmallLabel {
  font-size: x-small !important;
}

#sinceOutlookText {
  align-self: flex-end;
  margin-bottom: 20px;
  margin-right: 2em;
}

.relative {
  position: relative;
}

.extrapolated {
  margin-top: -2em;
  margin-bottom: 2em;
  align-self: center;
}

.extrapolatedSmallMargin {
  margin-top: 0em;
  margin-bottom: 2em;
  align-self: center;
}

.containerDropdown {
  display: flex;
  align-self: stretch;
  margin-right: 2em;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 2em;
}

.companyName {
  align-self: flex-end;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  flex-grow: 1;
  margin-bottom: 5px;
  margin-left: 1em;
}

#companyLogo {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

.backButtonContainer {
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
}

.dropdownContainer {
  margin-left: 2em;
  width: 190px;
}

.dropdownControlContainer {
  background-color: #fcfefa;

  border-radius: 100px;
  border: 0px;
  box-shadow: inset 0px 0px 8px rgba(50, 126, 44, 0.12);

  display: flex;
  flex-direction: column;
}

.dropdownMenu {
  background-color: #fcfefa;
  border-width: 0px;
  box-shadow: inset 0px 0px 4px rgba(50, 126, 44, 0.12);
  margin-top: 2px;
  border-radius: 20px;
}

.dropdownPlaceholder {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #7f909e;
}

.placeholderLeft {
  margin-left: 10px;
}

.Dropdown-option {
  color: #7f909e;
  margin-left: 10px;
}

.dropdownArrow {
  border-color: #7f909e transparent transparent;
  top: 18px;
  right: 15px;
}

.is-open .Dropdown-arrow {
  border-color: transparent transparent #7f909e;
}

.buttonContainer {
  height: 2em;

  background: #50b748;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2em;
  padding-right: 2em;
}

.buttonContainer p {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 0px;

  text-align: right;

  color: #ffffff;
}

.greenBorder {
  border: 2px solid #4fb654;
  background: white;
}

.greenBorder p {
  color: #4fb654;
}

.buttonContainer:hover {
  opacity: 0.6;
  cursor: pointer;
}

.buttonContainer img {
  margin-left: 1em;
}

.scoreDetailsButton {
  padding-left: 10px;
  padding-right: 10px;
  border-width: 2px;
}

.simpleChartContainer {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 2em;
  margin-left: 1em;
  margin-right: 1em;
}

.simpleChartContainer.home {
  margin-left: 0em;
  margin-right: 0em;

  min-width: 200px;
}

.simpleChartContainerColumn {
  justify-content: flex-start;
  margin: 0em;
  flex: 1 0 auto;
}

.simpleChart {
  display: flex;
  align-items: center;
}

.simpleChart.end {
  justify-content: flex-end;
}

.homeLargeChart {
  width: 6em;
  height: 6em;
}

.smallSimpleChart {
  width: 6em;
  height: 6em;
}

.viewMore {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;

  -webkit-text-decoration-line: underline;

          text-decoration-line: underline;

  color: #7f909e;
}

.viewMore.center {
  margin-bottom: 0px;
  text-align: center;
}

.viewMore:hover {
  opacity: 0.6;
  cursor: pointer;
}

.roiContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 7em;
  height: 7em;
  position: relative;
}

.roiBackground {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
  width: 6em;
  height: 6em;
  margin-left: 10px;
}

.legend.roi {
  width: 144px;
}

.legendColumn {
  width: auto;
  margin-left: 0em;
}

.chart-container {
  display: flex;
  flex-direction: "row";
  align-items: center;
  justify-content: center;
  flex-basis: 0em;
  flex-grow: 1;
  margin-top: 2em;
  margin-bottom: 2em;
  margin-left: 1em;
  margin-right: 1em;
}

.chart {
  width: 8em;
  height: 8em;
}

.circle {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.legend {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  max-width: 130px;
  min-width: 130px;
}

.legend.small {
  max-width: 100px;
  min-width: 100px;
}

.legend.lqi {
  max-width: 100px;
  min-width: 0px;
  margin-left: 2px;
}

.legend.engagement {
  max-width: 130px;
  min-width: 130px;
}

@media screen and (min-width: 1920px) {
  .legend {
    margin-left: 30px;
    max-width: 144px;
    min-width: 144px;
  }

  .legend.lqi {
    margin-left: 20px;
  }

  .legend.legend.engagement {
    max-width: 144px;
    min-width: 144px;
  }
}

.legend-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.dot {
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  margin-right: 5px;
}

.legend-item span {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #757575;
}

span {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #333333;
}

.chartTitle {
  color: #2e2e2e;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: start;
}

@media screen and (min-width: 1920px) {
  .chartTitle {
    font-size: 16px;
  }
}

.roiText {
  color: #4fb654;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;

  margin-left: 40px;
  margin-bottom: 6px;
}

.lockedContainer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: rgba(211, 211, 211, 0.7);
}

.lockedInnerContainer {
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.absoluteDetailedCurveChartMainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 2000;
}

.absoluteDetailedCurveChartContainer {
  background-color: white;
  box-shadow: 0px 0px 12px rgba(61, 62, 61, 0.1);

  z-index: 200;
  min-width: 400px;
  height: 300px;
  opacity: 1;
  z-index: 2000;

  padding: 1em;
  padding-left: 2em;
  padding-right: 2em;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 12px;
}

.absoluteDetailedCurveChartContainer.normal {
  transform: translate(50%, 50%);
}

.absoluteDetailedCurveChartContainer.left {
  transform: translate(-75%, 50%) !important;
}

.absoluteDetailedCurveChartContainer.indicatorNormal {
  transform: translate(60%, 25%);
}

.absoluteDetailedCurveChartContainer.indicatorLeft {
  transform: translate(-60%, 25%) !important;
}

.absoluteDetailedCurveChartContainer.top {
  transform: translate(60%, -25%) !important;
}

.absoluteDetailedCurveChartContainer.left.top {
  transform: translate(-75%, -25%) !important;
}

.absoluteDetailedCurveChartContainer.indicatorLeft.top {
  transform: translate(-60%, -25%) !important;
}

.detailedCurveLeftLabel {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  color: #45525e;
}

.detailedCurvedBottomLabel {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;

  color: #45525e;
}

.detailedCurveChartContainer {
  height: 420px;
  padding-left: 4em;
  padding-right: 4em;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  margin-top: 2em;
}

.detailedCurveChartContainerOverlay {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.detailedCurveChartSvgContainer {
  flex-grow: 1;
  margin-top: 2em;
}

.detailedCurveChartLegendContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  margin-bottom: 1em;
}

.detailedCurveChartLegendContainer.lastUpdated {
  margin-bottom: 0em;
}

.detailedCurveChartLegendContainer.lastUpdated span {
  color: #7f909e;
  font-size: 12px;
}
.detailedCurveCircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 2px;
}

.detailedCurveLegendLabel {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  color: #757575;
  margin-right: 30px;
  margin-left: 5px;
}

.detailedCurveTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 1em;
}

.detailedCurveTitle {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;

  color: #334050;
}

.detailedCurveTitleContainer.center {
  justify-content: center;
}

.curveChartContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 0px;

  margin-top: 15px;
  width: 100%;
}

.curveChartSvg {
  flex-grow: 1;
}

.curveChartFooter {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 20px;
}

.curveChartFooterCircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 2px;
}

.curveChartFooterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
}

.curveChartFooterContainer > span {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  color: #757575;
}

.curveChartSvgContainer {
  width: 90%;
  position: relative;
  flex-grow: 1;
  min-height: 0px;
  margin-left: 5%;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 85%;
  margin-left: 7.5%;
}

.curveChartSquareContainer > svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.curveChartSquareContainer {
  padding-bottom: 100%;
  flex-grow: 1;
  position: relative;
}

@-webkit-keyframes drawPath {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes drawPath {
  100% {
    stroke-dashoffset: 0;
  }
}

.animateSVGPath {
  transition: stroke-dashoffset 2s linear;
  stroke-dashoffset: 0px !important;
}

#tooltipContainer {
  position: absolute;
  right: 0px;
  top: 0px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

#textContainer {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(79, 182, 84, 0.19);
  border-radius: 12px;
  padding: 2em;
  margin-right: 1em;

  max-width: 400px;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  align-items: center;
}

#tooltipTitle {
  font-family: Source Sans Pro;
  font-size: 20px;
  margin-bottom: 1em;
  color: #334050;
}

#tooltipDescription p,
span {
  font-family: Source Sans Pro;
  font-size: 16px;
  color: #334050;
  line-height: 24px;
}

#tooltipImage {
  padding: 8px;
}

@media screen and (min-width: 1920px) {
  #tooltipImage {
    padding: 1em;
  }
}

#feedbackSvg {
  flex-grow: 1;
  width: 600px;
}

.feedbackLabel {
  font-family: Source Sans Pro;
  color: #45525e;
  font-size: 14px;
}

.feedbackModal {
  padding-left: 2em;
  padding-right: 2em;
  max-width: 600px !important;
  max-height: 800px;
  align-self: stretch;
  margin-top: 3em;
  margin-bottom: 3em;
}

.feedbackSeparator {
  align-self: stretch;
  border-bottom: 1px solid #d6e6ee;
  height: 1px;
}

#slider {
  flex-grow: 1;
  margin-left: 10px;
  margin-right: 10px;
}

#sliderContainer {
  max-height: 40px;
  align-self: stretch;
  margin-left: 3em;
  margin-right: 3em;

  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
  margin-top: 1em;
}

#sliderContainer span {
  color: #f17036;
  font-family: Source Sans Pro;
  font-size: 12px;
}

#sliderValueContainer {
  display: flex;
  flex-direction: column;
  padding-top: 1em;
}

#sliderValue {
  align-self: center;
  font-size: 16px;
  font-family: Source Sans Pro;

  color: #334050;
}

.emojiContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 2em;
  margin-right: 2em;
}

.emojiValue {
  font-family: Source Sans Pro;
  font-size: 16px;
}

.emoji {
  font-size: 24px;
}

#emojiRowContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 2em;
}

#feedbackTopLabel {
  align-self: center;
  margin-top: 2em;
  color: #334050;
  font-family: Source Sans Pro;
  font-size: 20px;
}

#giveFeedbackContainer {
  position: absolute;
  bottom: 0px;
  left: calc(100vw / 2 - (3 * (28px + 2em) / 2));
  box-shadow: 0px 0px 24px rgba(11, 34, 12, 0.2);
  background-color: white;
  border-radius: 12px;
  padding-bottom: 2em;
  padding-left: 2em;
  padding-right: 2em;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;

  -webkit-animation: feedbackScaleUp 1s ease none;

          animation: feedbackScaleUp 1s ease none;
  transform-origin: center bottom;
}

#leaveFeedack {
  text-align: center;

  font-size: 28px;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 1em;
}

#chooseFace {
  margin-top: 1em;
}

#leaveFeedbackClose {
  align-self: flex-end;
  padding-top: 1em;
  padding-left: 2em;
  padding-bottom: 1em;
}

#leaveFeedbackClose:hover {
  opacity: 0.7;
  cursor: pointer;
}

@-webkit-keyframes feedbackScaleUp {
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes feedbackScaleUp {
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

.giveFeedbackExit {
  transition: transform 500ms ease-out, opacity 500ms ease-out;
  transform: translateY(100%);
  opacity: 0;
}

#giveFeedbackModalContainer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.loaderContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100vw - 4px);
  height: calc(100vh - 4px);

  justify-content: center;
  align-items: center;
  display: flex;
  overflow-y: scroll;
}

.slowOpacity-enter {
  transform: translate3d(0%, 100%, 0);
  opacity: 1;
}
.slowOpacity-enter-active {
  transform: translate3d(0%, 0%, 0);
  z-index: -100 !important;
  opacity: 1;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}
.slowOpacity-exit {
  transform: translate3d(0%, 0%, 0);
  opacity: 1;
}
.slowOpacity-exit-active {
  transform: translate3d(0%, -100%, 0);
  opacity: 1;
  z-index: -100 !important;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}

.slowOpacity-reverse-enter {
  transform: translate3d(0%, -100%, 0);
  opacity: 1;
}
.slowOpacity-reverse-enter-active {
  transform: translate3d(0%, 0%, 0);
  z-index: -100 !important;
  opacity: 1;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}
.slowOpacity-reverse-exit {
  transform: translate3d(0%, 0%, 0);
  opacity: 1;
}
.slowOpacity-reverse-exit-active {
  transform: translate3d(0%, 100%, 0);
  opacity: 1;
  z-index: -100 !important;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}
.opacity-enter {
  opacity: 0;
}

.opacity-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}

.opacity-exit {
  opacity: 1;
}

.opacity-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.barchartContainer {
  display: flex;

  flex-direction: column;
  align-items: center;
}

.barchartContainer span {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;

  color: #334050;
}

.barchartIcon {
  width: 50px;
  height: 50px;

  -webkit-filter: invert(1);

          filter: invert(1);
}

.barchartLabelContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
  flex-direction: column;
  align-items: center;
}

.barchartLabelContainer span {
  font-family: Source Sans Pro;
  color: #2e2e2e;
  font-size: 16px;
  text-transform: uppercase;
}

.barchartLabelContainer span.goalName {
  max-width: 75%;
  text-align: center;
}

.barchart {
  height: 8px;
  border-radius: 8px;
}

.barchartLevel {
  position: absolute;
  height: 8px;
  border-radius: 8px;
  left: 0;
  top: 0;
}

.genderChartContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  width: 0px;
}

.genderChartTitle {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  margin-top: 0px;

  text-align: center;
  text-transform: uppercase;

  color: #162235;
}

.genderChartColumnContainer {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.genderChartTopContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.genderCircleContainer {
  width: 76px;
  height: 76px;
  margin-left: 15px;
  margin-right: 15px;
}

.genderLabel {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  text-align: center;

  color: #45525e;
}

.nonBinaryCircle {
  width: 10px;
  height: 10px;
  background-color: #1e6834;
  border-radius: 50%;
  margin-right: 5px;
}

.nonBinaryContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
}

.nonBinaryContainer > span {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  display: flex;
  align-items: center;

  color: #45525e;
}

.triangleChartContainer {
  position: relative;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 0px;
}

.triangleRowContainer {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.triangleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.triangleValue {
  position: absolute;
  bottom: 35px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
}

.triangleCategory {
  text-align: center;
  font-family: Source Sans Pro;
  font-weight: bold;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;

  margin-top: 2px;
  color: #7f909e;
}

.triangleChartLabel {
  text-align: center;
  margin-top: 0px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
  text-transform: uppercase;

  color: #162235;
}
.triangleSvg {
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-width: 10;
}

.mapChartContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  align-items: center;
  width: 0px;
}

.mapChartContainer > p {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
  text-transform: uppercase;

  color: #162235;
}

.mapContainer {
  margin-top: 30px;
  position: relative;
}

.mapLegendContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 30px;
  top: -25px;
}

.mapLegendContainer > span {
  font-family: Source Sans Pro;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;

  align-items: center;
  text-align: center;

  color: #233142;
}

.mapPin {
  width: 24px;
  height: 24px;
}

.recommendationContainer {
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(61, 62, 61, 0.1);
  border-radius: 12px;
  margin-right: 2em;

  display: flex;
  flex-basis: 0;
  flex-grow: 0.333333;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  position: relative;
}

.recommendationContainer:hover {
  opacity: 0.7;
  cursor: pointer;
}

.recommendationContainer img {
  height: 200px;
  object-fit: cover;
}

.recommendationTitle {
  font-family: Source Sans Pro;
  font-size: 18px;
  align-self: center;

  text-align: center;
  color: #334050;
  font-weight: 600;
  min-height: 36px;
  margin-top: 1em;
  margin-bottom: 1em;
}

.recommendationTitle.summary {
  margin-bottom: 0.5em;
  margin-top: 1em;
}

.recommendationExcerpt {
  height: 48px;
  margin-bottom: 2em;
}

.recommendationExcerpt p {
  margin-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  font-family: Source Sans Pro;
  font-size: 16px;
  color: #334050;
  margin-left: 10px;
  margin-right: 10px;
}

.recommendationViewMore {
  align-self: center;
  margin-top: 1em;
  font-family: Source Sans Pro;
  padding-bottom: 1em;

  color: #7ad077;
  text-decoration: underline;
}

.recommendationPriority {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 40px;
}

#recommendationModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  -webkit-animation-iteration-count: 1;

          animation-iteration-count: 1;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation: fade-in ease 1s;
          animation: fade-in ease 1s;
}

@-webkit-keyframes slide-up-fade-in-recommendation {
  0% {
    opacity: 0;
    transform: translate(0%, 100%);
  }
  100% {
    opacity: 1;
    transform: translate(0%, 0%);
  }
}

@keyframes slide-up-fade-in-recommendation {
  0% {
    opacity: 0;
    transform: translate(0%, 100%);
  }
  100% {
    opacity: 1;
    transform: translate(0%, 0%);
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#recommendationModal {
  background-color: white;
  box-shadow: 0px 0px 40px rgba(61, 62, 61, 0.4);
  border-radius: 12px;

  height: calc(100% - 4em);

  max-width: 600px;
  display: flex;
  flex-direction: column;
  padding-bottom: 1em;

  -webkit-animation-iteration-count: 1;

          animation-iteration-count: 1;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation: slide-up-fade-in-recommendation ease 1s;
          animation: slide-up-fade-in-recommendation ease 1s;
}

#modal {
  background-color: white;
  box-shadow: 0px 0px 40px rgba(61, 62, 61, 0.4);
  border-radius: 12px;

  max-width: 600px;
  display: flex;
  padding-bottom: 1em;
  padding-left: 2em;
  padding-right: 2em;
  justify-content: center;

  -webkit-animation-iteration-count: 1;

          animation-iteration-count: 1;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation: slide-up-fade-in-recommendation ease 1s;
          animation: slide-up-fade-in-recommendation ease 1s;
}

#chartModal {
  background-color: white;
  box-shadow: 0px 0px 40px rgba(61, 62, 61, 0.4);
  border-radius: 12px;

  max-width: 600px;
  display: flex;
  padding-bottom: 1em;
  padding-left: 2em;
  padding-right: 2em;
  justify-content: center;

  -webkit-animation-iteration-count: 1;

          animation-iteration-count: 1;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation: slide-up-fade-in-recommendation ease 1s;
          animation: slide-up-fade-in-recommendation ease 1s;
  position: fixed;
}

@media screen and (min-width: 1920px) {
  #recommendationModal {
    height: calc(100% - 8em);
  }
}

#recommendationModal img {
  max-height: 200px;
  object-fit: cover;
  margin-bottom: 1em;
}

.recommendationModalDescription {
  height: 0px;
  flex-grow: 1;
  overflow-y: scroll;
}

.recommendationModalDescription p {
  margin-left: 1em;
  margin-right: 1em;
  font-family: Source Sans Pro;
  color: #334050;
  font-size: 16px;
}

.recommendationModalDescription h3 {
  margin-left: 1em;
  margin-right: 1em;
  font-family: Source Sans Pro;
  color: #334050;
  font-size: 18px;
}

.slideOutModal {
  transition: transform 1s ease;
  transform-origin: top;
}

.fadeOutContainer {
  transition: opacity 1s ease;
  opacity: 0;
}

.recommendationsLoaderContainer {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.fillChartContainer {
  display: flex;
  flex-direction: row;
}

.fillChartContainerHoverable:hover {
  opacity: 0.7;
  cursor: pointer;
}

.fillChartLabel {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;

  text-align: center;
  text-transform: uppercase;

  color: #162235;
  margin-top: 5px;
  margin-right: 5px;
  padding-bottom: 1.5em;
}

@media screen and (min-width: 1920px) {
  .fillChartLabel {
    font-size: 16px;
    line-height: 16px;
  }
}

.fillChartImage {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.fillChartRightLabel {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;

  display: flex;
  align-items: center;
  text-align: right;

  color: #45525e;
}

.fillChartLegendContainer {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: center;
}

.fillChartLegendEntry {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
}

.fillChartLegendEntry > span {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;

  display: flex;
  align-items: center;
  text-align: right;

  color: #45525e;
}

.fillChartCircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 2px;
}

.fillChartClickContainer:hover {
  opacity: 0.7;
  cursor: pointer;
}

.fillChartRect {
  -webkit-animation: scaleUp;
          animation: scaleUp;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  transform-origin: bottom;
  transform-box: fill-box;
}

.verticalBarChartsContainer {
  margin-bottom: 10px;
  margin-top: 2em;
  position: relative;

  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex-grow: 1;
}

.verticalBarChartsLeftContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
}

.verticalBarChartsLeftContainer > span {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  height: 16px;

  color: #45525e;
  position: relative;
  top: -8px;
}

.verticalBarChartsGridContainer {
  flex-grow: 1;
  margin-left: 30px;
  position: relative;
  display: flex;
}

.verticalBarChartsGridSubContainer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.verticalBarChartsGraphContainer {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-left: 20px;
  flex-grow: 1;
  justify-content: center;
}

@-webkit-keyframes scaleUp {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes scaleUp {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

.verticalBarChartsContainerBarBackground {
  width: 30px;
  border-radius: 8px;
  position: relative;
  height: 100%;

  -webkit-animation-duration: 500ms;

          animation-duration: 500ms;
  -webkit-animation-name: scaleUp;
          animation-name: scaleUp;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  transform-origin: bottom;
}

.verticalBarChartsContainerBarBackground.large {
  width: 45px;
}

.verticalBarChartsContainerBar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #7ad077;
}

.verticalBarChartsLinesContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 20px;
}

.verticalBarChartsLine {
  border-top: 1px dashed #c7c7c7;
  height: 16px;
  margin-left: 60px;
}

.verticalBarChartsLineBottom {
  border-bottom: 1px dashed #c7c7c7;
  height: 16px;
  margin-left: 60px;
}

.verticalBarChartsLabelsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
}

.verticalBarChartVerticalGraphContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  margin-bottom: 20px;
}

#verticalBarChartsLabelsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 90px;
  margin-right: 10px;
}

#verticalBarChartsLabelsContainer > p {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  text-align: center;
  margin-bottom: 0px;
  margin-top: 8px;
  width: 100px;
}

.absenteeismPresenteeismContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.absenteeismPresenteeismSubContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.verticalSimpleBarChartContainer {
  width: 100%;
  height: 100%;
  margin-top: 2em;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-bottom: 20px;
  min-width: 260px;

  flex-grow: 1;
}

.verticalSimpleBarChartVerticalContainer {
  width: 100%;
  height: 100%;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
}

.verticalSimpleBarChartLeftContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.verticalSimpleBarChartLeftContainer > span {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  position: relative;

  color: #45525e;
}

.verticalSimpleBarChartGridContainer {
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
  margin-left: 20px;
  position: relative;
}

.verticalSimpleBarChartLine {
  height: 16px;
  border-top: 1px dashed #c7c7c7;
}

.verticalSimpleBarChartLineBottom {
  height: 16px;
  border-bottom: 1px dashed #c7c7c7;
}

.verticalSimpleBarChartDrawContainer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.verticalSimpleBarChartBar {
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;

  -webkit-animation-name: scaleUp;

          animation-name: scaleUp;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  transform-origin: bottom;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  min-height: 0px;
  flex-basis: 0;
}

.verticalSimpleBarChartBar > span {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  text-align: center;

  color: #ffffff;
}

.arrowChartFullContainer {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 0px;
  justify-content: center;
  padding-top: 2em;
  padding-bottom: 2em;

  width: 100%;
  max-width: 520px;
  margin-bottom: 10px;
}

.arrowChartContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
  justify-content: center;
  flex-grow: 1;

  max-width: 75%;
  margin-left: 12.5%;
}

.arrowChartTopContainer {
  position: relative;
  padding-bottom: 100%;
}

.arrowChartTopContainerSquare {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.arrowChartTopContainer:after {
  padding-bottom: 100%;
  background-color: green;
}

.arrowChartAxisLabel {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #aec2ce;
}

.arrowChartLeftAxis {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  min-width: 15px;
  margin-bottom: 10px;
}

.arrowChartAxisSegment {
  flex-basis: 0;
  flex-grow: 1;
}

.arrowChartGraphContainer {
  flex-grow: 1;
  align-self: stretch;

  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.arrowChartAxisContainer {
  flex-grow: 1;
  aspect-ratio: 1;

  display: flex;
  align-items: stretch;
}

.arrowChartBottomAxis {
  height: 15px;
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}

.arrowChartLeftLabelContainer {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  margin-left: -40px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.arrowChartBottomLabelContainer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -30px;
}

.arrowChartRotate {
  transform: rotate(-90deg);
  margin-left: -10px;
}

.arrowChartMonthLabel {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 50;
  font-size: 4px;

  fill: #7f909e;
}

.arrowChartValueLabel {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 4px;

  fill: #45525e;
}

.arrowChartFullContainer > p {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;

  text-align: center;

  color: #4fb654;
  margin-bottom: 0px;
  margin-top: 0px;
}

.arrowChartFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 50px;
}

.arrowChartFooterCircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 2px;
  background-color: #45525e;
  margin-right: 5px;
}

.arrowChartFooter > span {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;

  color: #757575;
}

.arrowChartGraphLabel {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 50;
  font-size: 3px;

  text-align: center;

  fill: #7b8e9e;
}

#svgContainer {
  width: 100%;
  height: 100%;
}

.fillBarChartLeftContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: 2em;
  align-self: stretch;
  padding-left: 3em;
  padding-right: 3em;
}

.fillBarChartLeftRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fillBarChartTitle {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;

  color: #334050;
}

.fillBartChartTitlePercentage {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 52px;
  margin-right: 2em;
}

.fillBarChartTitleGreen {
  color: #4fb654;
}

.fillBarChartTitleRed {
  color: rgb(153, 36, 26);
}

.fillBarChartDescriptionLabel {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  color: #334050;
  margin-bottom: 20px;
  margin-top: 10px;
}

p {
  color: #2c3848;
}

span {
  color: #2c3848;
}

li {
  color: #2c3848;
}

.splashRightContainer {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  width: 50vw;
}

.splashRightContainer.top {
  top: 0;
  z-index: -1;
}

.splashRightBackground {
  position: absolute;
  right: 0;
  height: 100vh;
}

.splashRightImage {
  position: absolute;
  bottom: 0em;
  right: 0em;
  transform: scale(0.9);
}

.splashContainer {
  display: flex;
  height: 100vh;
  flex-direction: column;

  width: calc(100vw - 8em);
  padding-left: 8em;
  position: relative;
  overflow: hidden;
}

.splashTextContainer {
  align-self: flex-start;
  flex-grow: 1;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}

.splashTitle {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 76px;
}

.splashTitle.noMargin {
  margin-top: 0px;
}

.splashTitle.small {
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 20px;
}

.splashText {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 52px;
  margin-top: 0px;
}

.splashTextContainer {
  width: 40vw;
}

.splashDiscoveryButton {
  padding-top: 10px;
  padding-bottom: 10px;
}

.ellipse1 {
  position: absolute;
  left: 30px;
  top: 70%;
}

.ellipse2 {
  position: absolute;
  bottom: 20%;
  left: 30%;
}

.ellipse3 {
  position: absolute;
  top: 20%;
  left: 40%;
}

.ellipse4 {
  position: absolute;
  top: 10%;
  right: calc(100vh * 1.03 / 2);
}

.centerChildren {
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

.centerChildren > img {
  max-height: 500px;
}

.input {
  margin-top: 2em;
}

.signUpButton {
  width: calc(256px - 2em);
  margin-top: 2em;
  padding-top: 10px;
  padding-bottom: 10px;
}

.bottomLeft {
  position: absolute;
  left: 0;
  bottom: 0;
}

.formContainer {
  position: relative;
  width: 40vw;
}

.formOverlay {
  position: absolute;
  top: 0;
}

.signUpDropDown {
  margin-top: 2em;
  width: 250px;
  margin-bottom: 2em;
}

.signUpDropDown.second {
  margin-top: 2em;
}

#triangle {
  pointer-events: none;
}

#top,
#second,
#third,
#fourth {
  pointer-events: all;
  cursor: pointer;
}

.topRight {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  width: calc(100vh * 0.7222625);
}

#signUpIndicatorContainer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

#signUpStep {
  margin-right: 1em;
  height: 450px;
  z-index: -1;
}

.animationContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  width: calc(100vw - 8em);
  padding-left: 8em;
}

#formContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  left: 80px;
}

#tutorialContent {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 10px;
}

ul {
  list-style: none;
}

li {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  margin-top: 10px;
}

ul li::before {
  content: "\2022";
  color: #2c3848;
  font-weight: bold;
  font-size: 24px;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

#signUpStepImageContainer {
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;
  height: 100%;
}

input {
  font-family: Source Sans Pro;
  font-size: 16px;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  align-self: stretch;
  margin-top: 2em;
  margin-right: 4em;
  z-index: 100;
}

.buttonShadow {
  box-shadow: 0px 2px 8px rgba(47, 118, 41, 0.2);
}

.inputContainer {
  background: #f2fded;
  box-shadow: inset 0px 0px 8px rgba(50, 126, 44, 0.08);
  border-radius: 100px;

  width: 256px;
  display: flex;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  height: 3em;
}

input {
  background-color: transparent;
  border-width: 0px;
  height: 3em;
  width: 100%;
}

input:focus {
  outline-width: 0px;
}

.inputContainer img {
  margin-right: 8px;
}

.inputErrorContainer {
  display: flex;
  flex-direction: column;
}

.inputError {
  color: red;
  font-size: 10px;
  height: 15px;
  margin-left: 10px;
}

.inputError.hidden {
  visibility: hidden;
}

.inputError.visible {
  visibility: visible;
}

.blur {
  filter: blur(2px);
  -webkit-filter: blur(2px);
  pointer-events: none;
}

.blurLarge {
  filter: blur(10px);
  -webkit-filter: blur(10px);
  pointer-events: none;
}

.blurWhite {
  background-color: white;
}

.highlight {
  padding-top: 2em;
  padding-bottom: 2em;
  box-shadow: 0px 0px 28px rgba(61, 62, 61, 0.4);
}

.highlightNoPadding {
  box-shadow: 0px 0px 28px rgba(61, 62, 61, 0.4);
}

.highlightIcon {
  width: 75px;
}

#highlightContainer {
  -webkit-animation: slide;
          animation: slide;
  display: flex;
  flex-direction: row;

  position: absolute;
  background-color: white;
  border-radius: 32px;
  align-items: center;
  padding: 20px;
  box-shadow: 0px 0px 16px rgba(44, 130, 63, 0.4);
  z-index: 1000;
}

#highlightDescription {
  max-width: 700px;
  margin-left: 15px;
  margin-right: 15px;
  font-family: Source Sans Pro;
}

.highlightSkipButton {
  margin-right: 10px;
}

@keyframes slide-up-fade-in {
  from {
    opacity: 0;
    transform: translate(-50%, 100%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@-webkit-keyframes slide-up-fade-in {
  from {
    opacity: 0;
    transform: translate(-50%, 100%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

#highlightIntroContainer {
  position: absolute;
  z-index: 2000;
  padding: 40px;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;

  background-color: white;
  border-radius: 32px;
  align-items: center;
  padding: 60px;
  box-shadow: 0px 0px 16px rgba(44, 130, 63, 0.4);
  max-width: 500px;

  -webkit-animation-iteration-count: 1;

          animation-iteration-count: 1;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation: slide-up-fade-in ease 1s;
          animation: slide-up-fade-in ease 1s;
}

#highlightIntroTitle {
  font-family: Source Sans Pro;
  font-weight: 600;
  font-size: 40px;
  line-height: 52px;
}

#highlightIntro {
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin-top: 40px;
}

#highlightImage {
  margin-top: 40px;
  margin-bottom: 40px;
}

.highlighIntroButton {
  width: 200px;
  margin-top: 30px;
  height: 60px;
}

#detailedScoreSVGContainer {
  align-self: stretch;
  flex-grow: 1;
  margin: 1em;
  overflow: visible;
}

.detailedChartAxisLabel {
  font-size: 14px;
  font-family: Source Sans Pro;
}

.fillPath {
  fill: linear-gradient(
      180.47deg,
      rgba(255, 255, 255, 0) 26.28%,
      #ffffff 99.37%
    ),
    linear-gradient(
      89.52deg,
      rgba(254, 190, 58, 0.5) 1.62%,
      rgba(173, 234, 164, 0.5) 99.85%
    );
}

.detailedChartLabel {
  align-self: start;
  margin-left: 2em;
  margin-top: 1em;
  color: #334050;
  font-size: 16px;
}

.detailedSvgLeftLabelContainer {
  align-self: stretch;
  flex-grow: 1;
  flex-direction: row;
  display: flex;
  align-items: stretch;
}

.detailedChartLeftLabel {
  transform: rotate(-90deg);
  margin-right: -40px;
  margin-left: -10px;
  color: #aec2ce;
  font-size: 14px;
  font-family: Source Sans Pro;
}

.detailedChartBottomLabel {
  color: #aec2ce;
  font-size: 14px;
  font-family: Source Sans Pro;
  padding-bottom: 1em;
}

.indicatorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 116px;
  min-width: 100px;
  flex-basis: 20%;
  flex-shrink: 0;

  margin-top: 2em;
  margin-bottom: 2em;
  position: relative;
}

.indicatorContainerLarge {
  width: 152px;
  height: 238px;
  align-items: center;
  flex-basis: auto;
}

.indicatorImageLarge {
  width: 152px;
  height: 152px;
}

.indicatorImageSmall {
  width: 27px;
  height: 27px;
}

.indicatorContainer > p {
  margin-top: 12px;
  margin-bottom: 0;
  text-align: center;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.indicatorContainerLarge > p {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  text-align: center;

  color: #2e2e2e;
}

.indicatorPercentage {
  border-radius: 8px;
  color: white !important;
  font-weight: normal !important;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 76px;
  align-self: center;
}

