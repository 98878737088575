#tooltipContainer {
  position: absolute;
  right: 0px;
  top: 0px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

#textContainer {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(79, 182, 84, 0.19);
  border-radius: 12px;
  padding: 2em;
  margin-right: 1em;

  max-width: 400px;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  align-items: center;
}

#tooltipTitle {
  font-family: Source Sans Pro;
  font-size: 20px;
  margin-bottom: 1em;
  color: #334050;
}

#tooltipDescription p,
span {
  font-family: Source Sans Pro;
  font-size: 16px;
  color: #334050;
  line-height: 24px;
}

#tooltipImage {
  padding: 8px;
}

@media screen and (min-width: 1920px) {
  #tooltipImage {
    padding: 1em;
  }
}
