.arrowChartFullContainer {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 0px;
  justify-content: center;
  padding-top: 2em;
  padding-bottom: 2em;

  width: 100%;
  max-width: 520px;
  margin-bottom: 10px;
}

.arrowChartContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
  justify-content: center;
  flex-grow: 1;

  max-width: 75%;
  margin-left: 12.5%;
}

.arrowChartTopContainer {
  position: relative;
  padding-bottom: 100%;
}

.arrowChartTopContainerSquare {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.arrowChartTopContainer:after {
  padding-bottom: 100%;
  background-color: green;
}

.arrowChartAxisLabel {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #aec2ce;
}

.arrowChartLeftAxis {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  min-width: 15px;
  margin-bottom: 10px;
}

.arrowChartAxisSegment {
  flex-basis: 0;
  flex-grow: 1;
}

.arrowChartGraphContainer {
  flex-grow: 1;
  align-self: stretch;

  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.arrowChartAxisContainer {
  flex-grow: 1;
  aspect-ratio: 1;

  display: flex;
  align-items: stretch;
}

.arrowChartBottomAxis {
  height: 15px;
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}

.arrowChartLeftLabelContainer {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  margin-left: -40px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.arrowChartBottomLabelContainer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -30px;
}

.arrowChartRotate {
  transform: rotate(-90deg);
  margin-left: -10px;
}

.arrowChartMonthLabel {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 50;
  font-size: 4px;

  fill: #7f909e;
}

.arrowChartValueLabel {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 4px;

  fill: #45525e;
}

.arrowChartFullContainer > p {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;

  text-align: center;

  color: #4fb654;
  margin-bottom: 0px;
  margin-top: 0px;
}

.arrowChartFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 50px;
}

.arrowChartFooterCircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 2px;
  background-color: #45525e;
  margin-right: 5px;
}

.arrowChartFooter > span {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;

  color: #757575;
}

.arrowChartGraphLabel {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 50;
  font-size: 3px;

  text-align: center;

  fill: #7b8e9e;
}
