.barchartContainer {
  display: flex;

  flex-direction: column;
  align-items: center;
}

.barchartContainer span {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;

  color: #334050;
}

.barchartIcon {
  width: 50px;
  height: 50px;

  filter: invert(1);
}

.barchartLabelContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
  flex-direction: column;
  align-items: center;
}

.barchartLabelContainer span {
  font-family: Source Sans Pro;
  color: #2e2e2e;
  font-size: 16px;
  text-transform: uppercase;
}

.barchartLabelContainer span.goalName {
  max-width: 75%;
  text-align: center;
}

.barchart {
  height: 8px;
  border-radius: 8px;
}

.barchartLevel {
  position: absolute;
  height: 8px;
  border-radius: 8px;
  left: 0;
  top: 0;
}
