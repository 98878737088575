.buttonContainer {
  height: 2em;

  background: #50b748;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2em;
  padding-right: 2em;
}

.buttonContainer p {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 0px;

  text-align: right;

  color: #ffffff;
}

.greenBorder {
  border: 2px solid #4fb654;
  background: white;
}

.greenBorder p {
  color: #4fb654;
}

.buttonContainer:hover {
  opacity: 0.6;
  cursor: pointer;
}

.buttonContainer img {
  margin-left: 1em;
}

.scoreDetailsButton {
  padding-left: 10px;
  padding-right: 10px;
  border-width: 2px;
}
