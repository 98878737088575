.dropdownContainer {
  margin-left: 2em;
  width: 190px;
}

.dropdownControlContainer {
  background-color: #fcfefa;

  border-radius: 100px;
  border: 0px;
  box-shadow: inset 0px 0px 8px rgba(50, 126, 44, 0.12);

  display: flex;
  flex-direction: column;
}

.dropdownMenu {
  background-color: #fcfefa;
  border-width: 0px;
  box-shadow: inset 0px 0px 4px rgba(50, 126, 44, 0.12);
  margin-top: 2px;
  border-radius: 20px;
}

.dropdownPlaceholder {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #7f909e;
}

.placeholderLeft {
  margin-left: 10px;
}

.Dropdown-option {
  color: #7f909e;
  margin-left: 10px;
}

.dropdownArrow {
  border-color: #7f909e transparent transparent;
  top: 18px;
  right: 15px;
}

.is-open .Dropdown-arrow {
  border-color: transparent transparent #7f909e;
}
