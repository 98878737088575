.detailedCurveLeftLabel {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  color: #45525e;
}

.detailedCurvedBottomLabel {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;

  color: #45525e;
}

.detailedCurveChartContainer {
  height: 420px;
  padding-left: 4em;
  padding-right: 4em;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  margin-top: 2em;
}

.detailedCurveChartContainerOverlay {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.detailedCurveChartSvgContainer {
  flex-grow: 1;
  margin-top: 2em;
}

.detailedCurveChartLegendContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  margin-bottom: 1em;
}

.detailedCurveChartLegendContainer.lastUpdated {
  margin-bottom: 0em;
}

.detailedCurveChartLegendContainer.lastUpdated span {
  color: #7f909e;
  font-size: 12px;
}
.detailedCurveCircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 2px;
}

.detailedCurveLegendLabel {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  color: #757575;
  margin-right: 30px;
  margin-left: 5px;
}

.detailedCurveTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 1em;
}

.detailedCurveTitle {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;

  color: #334050;
}

.detailedCurveTitleContainer.center {
  justify-content: center;
}
