#feedbackSvg {
  flex-grow: 1;
  width: 600px;
}

.feedbackLabel {
  font-family: Source Sans Pro;
  color: #45525e;
  font-size: 14px;
}

.feedbackModal {
  padding-left: 2em;
  padding-right: 2em;
  max-width: 600px !important;
  max-height: 800px;
  align-self: stretch;
  margin-top: 3em;
  margin-bottom: 3em;
}

.feedbackSeparator {
  align-self: stretch;
  border-bottom: 1px solid #d6e6ee;
  height: 1px;
}

#slider {
  flex-grow: 1;
  margin-left: 10px;
  margin-right: 10px;
}

#sliderContainer {
  max-height: 40px;
  align-self: stretch;
  margin-left: 3em;
  margin-right: 3em;

  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
  margin-top: 1em;
}

#sliderContainer span {
  color: #f17036;
  font-family: Source Sans Pro;
  font-size: 12px;
}

#sliderValueContainer {
  display: flex;
  flex-direction: column;
  padding-top: 1em;
}

#sliderValue {
  align-self: center;
  font-size: 16px;
  font-family: Source Sans Pro;

  color: #334050;
}

.emojiContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 2em;
  margin-right: 2em;
}

.emojiValue {
  font-family: Source Sans Pro;
  font-size: 16px;
}

.emoji {
  font-size: 24px;
}

#emojiRowContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 2em;
}

#feedbackTopLabel {
  align-self: center;
  margin-top: 2em;
  color: #334050;
  font-family: Source Sans Pro;
  font-size: 20px;
}

#giveFeedbackContainer {
  position: absolute;
  bottom: 0px;
  left: calc(100vw / 2 - (3 * (28px + 2em) / 2));
  box-shadow: 0px 0px 24px rgba(11, 34, 12, 0.2);
  background-color: white;
  border-radius: 12px;
  padding-bottom: 2em;
  padding-left: 2em;
  padding-right: 2em;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;

  animation: feedbackScaleUp 1s ease none;
  transform-origin: center bottom;
}

#leaveFeedack {
  text-align: center;

  font-size: 28px;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 1em;
}

#chooseFace {
  margin-top: 1em;
}

#leaveFeedbackClose {
  align-self: flex-end;
  padding-top: 1em;
  padding-left: 2em;
  padding-bottom: 1em;
}

#leaveFeedbackClose:hover {
  opacity: 0.7;
  cursor: pointer;
}

@keyframes feedbackScaleUp {
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

.giveFeedbackExit {
  transition: transform 500ms ease-out, opacity 500ms ease-out;
  transform: translateY(100%);
  opacity: 0;
}

#giveFeedbackModalContainer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
