.absenteeismPresenteeismContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.absenteeismPresenteeismSubContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
