.absoluteDetailedCurveChartMainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 2000;
}

.absoluteDetailedCurveChartContainer {
  background-color: white;
  box-shadow: 0px 0px 12px rgba(61, 62, 61, 0.1);

  z-index: 200;
  min-width: 400px;
  height: 300px;
  opacity: 1;
  z-index: 2000;

  padding: 1em;
  padding-left: 2em;
  padding-right: 2em;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 12px;
}

.absoluteDetailedCurveChartContainer.normal {
  transform: translate(50%, 50%);
}

.absoluteDetailedCurveChartContainer.left {
  transform: translate(-75%, 50%) !important;
}

.absoluteDetailedCurveChartContainer.indicatorNormal {
  transform: translate(60%, 25%);
}

.absoluteDetailedCurveChartContainer.indicatorLeft {
  transform: translate(-60%, 25%) !important;
}

.absoluteDetailedCurveChartContainer.top {
  transform: translate(60%, -25%) !important;
}

.absoluteDetailedCurveChartContainer.left.top {
  transform: translate(-75%, -25%) !important;
}

.absoluteDetailedCurveChartContainer.indicatorLeft.top {
  transform: translate(-60%, -25%) !important;
}
