.chart-container {
  display: flex;
  flex-direction: "row";
  align-items: center;
  justify-content: center;
  flex-basis: 0em;
  flex-grow: 1;
  margin-top: 2em;
  margin-bottom: 2em;
  margin-left: 1em;
  margin-right: 1em;
}

.chart {
  width: 8em;
  height: 8em;
}

.circle {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.legend {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  max-width: 130px;
  min-width: 130px;
}

.legend.small {
  max-width: 100px;
  min-width: 100px;
}

.legend.lqi {
  max-width: 100px;
  min-width: 0px;
  margin-left: 2px;
}

.legend.engagement {
  max-width: 130px;
  min-width: 130px;
}

@media screen and (min-width: 1920px) {
  .legend {
    margin-left: 30px;
    max-width: 144px;
    min-width: 144px;
  }

  .legend.lqi {
    margin-left: 20px;
  }

  .legend.legend.engagement {
    max-width: 144px;
    min-width: 144px;
  }
}

.legend-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.dot {
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  margin-right: 5px;
}

.legend-item span {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #757575;
}

span {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #333333;
}

.chartTitle {
  color: #2e2e2e;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: start;
}

@media screen and (min-width: 1920px) {
  .chartTitle {
    font-size: 16px;
  }
}

.roiText {
  color: #4fb654;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;

  margin-left: 40px;
  margin-bottom: 6px;
}
