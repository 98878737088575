.sideMenu {
  width: 72px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 1em;
  position: relative;
  padding-bottom: 3em;
}

.background {
  background: #7ad077;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 24px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.backgroundTop {
  top: 0;
}

.backgroundBottom {
  bottom: 0;
}

.logo {
  background: #7ad077;
  margin-bottom: 5em;
  z-index: 100;
}

.item {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.selected {
  background-color: #7ad077;
}

.unselected {
  background-color: white;
}

.selectedContainer {
  height: 101px;
  align-self: stretch;
  align-items: center;
  position: relative;
  justify-content: center;
  display: flex;
}

.unselectedContainer {
  width: 72px;
  height: 101px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rectangle {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 40px;
  background-color: white;
}

.item img {
  width: 22px;
  height: 22px;
}

.selectedCircle {
  border-radius: 50%;
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 68px;
  height: 68px;
}

.white {
  filter: brightness(0) invert(1);
}

.menuContainer {
  display: flex;
  align-self: stretch;
  align-items: stretch;
  background: #ffffff;
  box-shadow: 4px 0px 16px rgba(10, 41, 8, 0.08);

  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 300;
}

.textItemsContainer {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  width: 180px;
}

.textItems {
  display: flex;
  flex-direction: column;

  position: relative;
  align-items: flex-start;
  margin-left: 1em;
  margin-top: 12em;
  margin-right: 10px;
}

.textItems span:not(.textItemSelected) {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;

  color: #45525e;
}

.textItems span:not(.textItemSelected):hover {
  transform: scale(1.2);
  transform-origin: 0% 100%;
  cursor: pointer;
}

.textItemSelected {
  color: #4fb654 !important;
  font-size: 16px;
  padding-bottom: 2px;
  border-bottom: 1px solid #4fb654;
  align-self: flex-start;
  margin-bottom: 1em;
  padding-right: 3px;
  text-transform: uppercase;
}

.indentSmall {
  margin-left: 1em !important;
}

.indentLarge {
  margin-left: 2em !important;
  margin-top: -1em !important;
}

.menuItemsNotSelected {
  margin-bottom: 1em;
}

#logoutContainer {
  position: absolute;
  top: 30px;
  left: 90px;
  height: 0px;
  transition: all 0.3s ease-in-out;

  display: flex;
  box-shadow: 0px 0px 12px rgba(61, 62, 61, 0.1);
  background-color: white;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}

#logoutContainer > span {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  padding-left: 2em;
  padding-right: 2em;
}

#settings:hover + #logoutContainer,
#logoutContainer:hover {
  height: 60px;
}

#settings:hover + #logoutContainer > span,
#logoutContainer:hover > span {
  opacity: 1;
}

#logoutContainer,
#settings {
  cursor: pointer;
  z-index: 1;
}

#settings:hover {
  opacity: 0.7;
}

#logoutContainer > span:hover {
  transform: scale(1.2);
}

.selectedIconBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media screen and (min-width: 1920px) {
  .textItemSelected {
    font-size: 18px;
  }

  .textItems span:not(.textItemSelected) {
    font-size: 16px !important;
  }
}
