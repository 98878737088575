.verticalBarChartsContainer {
  margin-bottom: 10px;
  margin-top: 2em;
  position: relative;

  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex-grow: 1;
}

.verticalBarChartsLeftContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
}

.verticalBarChartsLeftContainer > span {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  height: 16px;

  color: #45525e;
  position: relative;
  top: -8px;
}

.verticalBarChartsGridContainer {
  flex-grow: 1;
  margin-left: 30px;
  position: relative;
  display: flex;
}

.verticalBarChartsGridSubContainer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.verticalBarChartsGraphContainer {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-left: 20px;
  flex-grow: 1;
  justify-content: center;
}

@keyframes scaleUp {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

.verticalBarChartsContainerBarBackground {
  width: 30px;
  border-radius: 8px;
  position: relative;
  height: 100%;

  animation-duration: 500ms;
  animation-name: scaleUp;
  animation-timing-function: ease-in-out;
  transform-origin: bottom;
}

.verticalBarChartsContainerBarBackground.large {
  width: 45px;
}

.verticalBarChartsContainerBar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #7ad077;
}

.verticalBarChartsLinesContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 20px;
}

.verticalBarChartsLine {
  border-top: 1px dashed #c7c7c7;
  height: 16px;
  margin-left: 60px;
}

.verticalBarChartsLineBottom {
  border-bottom: 1px dashed #c7c7c7;
  height: 16px;
  margin-left: 60px;
}

.verticalBarChartsLabelsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
}

.verticalBarChartVerticalGraphContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  margin-bottom: 20px;
}

#verticalBarChartsLabelsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 90px;
  margin-right: 10px;
}

#verticalBarChartsLabelsContainer > p {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  text-align: center;
  margin-bottom: 0px;
  margin-top: 8px;
  width: 100px;
}
