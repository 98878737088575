@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext");

p {
  color: #2c3848;
}

span {
  color: #2c3848;
}

li {
  color: #2c3848;
}

.splashRightContainer {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  width: 50vw;
}

.splashRightContainer.top {
  top: 0;
  z-index: -1;
}

.splashRightBackground {
  position: absolute;
  right: 0;
  height: 100vh;
}

.splashRightImage {
  position: absolute;
  bottom: 0em;
  right: 0em;
  transform: scale(0.9);
}

.splashContainer {
  display: flex;
  height: 100vh;
  flex-direction: column;

  width: calc(100vw - 8em);
  padding-left: 8em;
  position: relative;
  overflow: hidden;
}

.splashTextContainer {
  align-self: flex-start;
  flex-grow: 1;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}

.splashTitle {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 76px;
}

.splashTitle.noMargin {
  margin-top: 0px;
}

.splashTitle.small {
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 20px;
}

.splashText {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 52px;
  margin-top: 0px;
}

.splashTextContainer {
  width: 40vw;
}

.splashDiscoveryButton {
  padding-top: 10px;
  padding-bottom: 10px;
}

.ellipse1 {
  position: absolute;
  left: 30px;
  top: 70%;
}

.ellipse2 {
  position: absolute;
  bottom: 20%;
  left: 30%;
}

.ellipse3 {
  position: absolute;
  top: 20%;
  left: 40%;
}

.ellipse4 {
  position: absolute;
  top: 10%;
  right: calc(100vh * 1.03 / 2);
}

.centerChildren {
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

.centerChildren > img {
  max-height: 500px;
}

.input {
  margin-top: 2em;
}

.signUpButton {
  width: calc(256px - 2em);
  margin-top: 2em;
  padding-top: 10px;
  padding-bottom: 10px;
}

.bottomLeft {
  position: absolute;
  left: 0;
  bottom: 0;
}

.formContainer {
  position: relative;
  width: 40vw;
}

.formOverlay {
  position: absolute;
  top: 0;
}

.signUpDropDown {
  margin-top: 2em;
  width: 250px;
  margin-bottom: 2em;
}

.signUpDropDown.second {
  margin-top: 2em;
}

#triangle {
  pointer-events: none;
}

#top,
#second,
#third,
#fourth {
  pointer-events: all;
  cursor: pointer;
}

.topRight {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  width: calc(100vh * 0.7222625);
}

#signUpIndicatorContainer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

#signUpStep {
  margin-right: 1em;
  height: 450px;
  z-index: -1;
}

.animationContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  width: calc(100vw - 8em);
  padding-left: 8em;
}

#formContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  left: 80px;
}

#tutorialContent {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 10px;
}

ul {
  list-style: none;
}

li {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  margin-top: 10px;
}

ul li::before {
  content: "\2022";
  color: #2c3848;
  font-weight: bold;
  font-size: 24px;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

#signUpStepImageContainer {
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;
  height: 100%;
}

input {
  font-family: Source Sans Pro;
  font-size: 16px;
}
