.fillBarChartLeftContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: 2em;
  align-self: stretch;
  padding-left: 3em;
  padding-right: 3em;
}

.fillBarChartLeftRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fillBarChartTitle {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;

  color: #334050;
}

.fillBartChartTitlePercentage {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 52px;
  margin-right: 2em;
}

.fillBarChartTitleGreen {
  color: #4fb654;
}

.fillBarChartTitleRed {
  color: rgb(153, 36, 26);
}

.fillBarChartDescriptionLabel {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  color: #334050;
  margin-bottom: 20px;
  margin-top: 10px;
}
