.recommendationContainer {
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(61, 62, 61, 0.1);
  border-radius: 12px;
  margin-right: 2em;

  display: flex;
  flex-basis: 0;
  flex-grow: 0.333333;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  position: relative;
}

.recommendationContainer:hover {
  opacity: 0.7;
  cursor: pointer;
}

.recommendationContainer img {
  height: 200px;
  object-fit: cover;
}

.recommendationTitle {
  font-family: Source Sans Pro;
  font-size: 18px;
  align-self: center;

  text-align: center;
  color: #334050;
  font-weight: 600;
  min-height: 36px;
  margin-top: 1em;
  margin-bottom: 1em;
}

.recommendationTitle.summary {
  margin-bottom: 0.5em;
  margin-top: 1em;
}

.recommendationExcerpt {
  height: 48px;
  margin-bottom: 2em;
}

.recommendationExcerpt p {
  margin-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  font-family: Source Sans Pro;
  font-size: 16px;
  color: #334050;
  margin-left: 10px;
  margin-right: 10px;
}

.recommendationViewMore {
  align-self: center;
  margin-top: 1em;
  font-family: Source Sans Pro;
  padding-bottom: 1em;

  color: #7ad077;
  text-decoration: underline;
}

.recommendationPriority {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 40px;
}

#recommendationModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  animation-iteration-count: 1;
  animation-fill-mode: backwards;
  animation: fade-in ease 1s;
}

@keyframes slide-up-fade-in-recommendation {
  0% {
    opacity: 0;
    transform: translate(0%, 100%);
  }
  100% {
    opacity: 1;
    transform: translate(0%, 0%);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#recommendationModal {
  background-color: white;
  box-shadow: 0px 0px 40px rgba(61, 62, 61, 0.4);
  border-radius: 12px;

  height: calc(100% - 4em);

  max-width: 600px;
  display: flex;
  flex-direction: column;
  padding-bottom: 1em;

  animation-iteration-count: 1;
  animation-fill-mode: backwards;
  animation: slide-up-fade-in-recommendation ease 1s;
}

#modal {
  background-color: white;
  box-shadow: 0px 0px 40px rgba(61, 62, 61, 0.4);
  border-radius: 12px;

  max-width: 600px;
  display: flex;
  padding-bottom: 1em;
  padding-left: 2em;
  padding-right: 2em;
  justify-content: center;

  animation-iteration-count: 1;
  animation-fill-mode: backwards;
  animation: slide-up-fade-in-recommendation ease 1s;
}

#chartModal {
  background-color: white;
  box-shadow: 0px 0px 40px rgba(61, 62, 61, 0.4);
  border-radius: 12px;

  max-width: 600px;
  display: flex;
  padding-bottom: 1em;
  padding-left: 2em;
  padding-right: 2em;
  justify-content: center;

  animation-iteration-count: 1;
  animation-fill-mode: backwards;
  animation: slide-up-fade-in-recommendation ease 1s;
  position: fixed;
}

@media screen and (min-width: 1920px) {
  #recommendationModal {
    height: calc(100% - 8em);
  }
}

#recommendationModal img {
  max-height: 200px;
  object-fit: cover;
  margin-bottom: 1em;
}

.recommendationModalDescription {
  height: 0px;
  flex-grow: 1;
  overflow-y: scroll;
}

.recommendationModalDescription p {
  margin-left: 1em;
  margin-right: 1em;
  font-family: Source Sans Pro;
  color: #334050;
  font-size: 16px;
}

.recommendationModalDescription h3 {
  margin-left: 1em;
  margin-right: 1em;
  font-family: Source Sans Pro;
  color: #334050;
  font-size: 18px;
}

.slideOutModal {
  transition: transform 1s ease;
  transform-origin: top;
}

.fadeOutContainer {
  transition: opacity 1s ease;
  opacity: 0;
}

.recommendationsLoaderContainer {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}
