.containerDropdown {
  display: flex;
  align-self: stretch;
  margin-right: 2em;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 2em;
}

.companyName {
  align-self: flex-end;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  flex-grow: 1;
  margin-bottom: 5px;
  margin-left: 1em;
}

#companyLogo {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

.backButtonContainer {
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
}
