.triangleChartContainer {
  position: relative;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 0px;
}

.triangleRowContainer {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.triangleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.triangleValue {
  position: absolute;
  bottom: 35px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
}

.triangleCategory {
  text-align: center;
  font-family: Source Sans Pro;
  font-weight: bold;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;

  margin-top: 2px;
  color: #7f909e;
}

.triangleChartLabel {
  text-align: center;
  margin-top: 0px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
  text-transform: uppercase;

  color: #162235;
}
.triangleSvg {
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-width: 10;
}
