.curveChartContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 0px;

  margin-top: 15px;
  width: 100%;
}

.curveChartSvg {
  flex-grow: 1;
}

.curveChartFooter {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 20px;
}

.curveChartFooterCircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 2px;
}

.curveChartFooterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
}

.curveChartFooterContainer > span {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  color: #757575;
}

.curveChartSvgContainer {
  width: 90%;
  position: relative;
  flex-grow: 1;
  min-height: 0px;
  margin-left: 5%;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 85%;
  margin-left: 7.5%;
}

.curveChartSquareContainer > svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.curveChartSquareContainer {
  padding-bottom: 100%;
  flex-grow: 1;
  position: relative;
}

@keyframes drawPath {
  100% {
    stroke-dashoffset: 0;
  }
}

.animateSVGPath {
  transition: stroke-dashoffset 2s linear;
  stroke-dashoffset: 0px !important;
}
