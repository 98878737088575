.inputContainer {
  background: #f2fded;
  box-shadow: inset 0px 0px 8px rgba(50, 126, 44, 0.08);
  border-radius: 100px;

  width: 256px;
  display: flex;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  height: 3em;
}

input {
  background-color: transparent;
  border-width: 0px;
  height: 3em;
  width: 100%;
}

input:focus {
  outline-width: 0px;
}

.inputContainer img {
  margin-right: 8px;
}

.inputErrorContainer {
  display: flex;
  flex-direction: column;
}

.inputError {
  color: red;
  font-size: 10px;
  height: 15px;
  margin-left: 10px;
}

.inputError.hidden {
  visibility: hidden;
}

.inputError.visible {
  visibility: visible;
}
