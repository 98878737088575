.fillChartContainer {
  display: flex;
  flex-direction: row;
}

.fillChartContainerHoverable:hover {
  opacity: 0.7;
  cursor: pointer;
}

.fillChartLabel {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;

  text-align: center;
  text-transform: uppercase;

  color: #162235;
  margin-top: 5px;
  margin-right: 5px;
  padding-bottom: 1.5em;
}

@media screen and (min-width: 1920px) {
  .fillChartLabel {
    font-size: 16px;
    line-height: 16px;
  }
}

.fillChartImage {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.fillChartRightLabel {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;

  display: flex;
  align-items: center;
  text-align: right;

  color: #45525e;
}

.fillChartLegendContainer {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: center;
}

.fillChartLegendEntry {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
}

.fillChartLegendEntry > span {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;

  display: flex;
  align-items: center;
  text-align: right;

  color: #45525e;
}

.fillChartCircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 2px;
}

.fillChartClickContainer:hover {
  opacity: 0.7;
  cursor: pointer;
}

.fillChartRect {
  animation: scaleUp;
  animation-duration: 1s;
  animation-fill-mode: backwards;
  animation-timing-function: linear;
  transform-origin: bottom;
  transform-box: fill-box;
}
