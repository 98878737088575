.simpleChartContainer {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 2em;
  margin-left: 1em;
  margin-right: 1em;
}

.simpleChartContainer.home {
  margin-left: 0em;
  margin-right: 0em;

  min-width: 200px;
}

.simpleChartContainerColumn {
  justify-content: flex-start;
  margin: 0em;
  flex: 1 0 auto;
}

.simpleChart {
  display: flex;
  align-items: center;
}

.simpleChart.end {
  justify-content: flex-end;
}

.homeLargeChart {
  width: 6em;
  height: 6em;
}

.smallSimpleChart {
  width: 6em;
  height: 6em;
}

.viewMore {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;

  text-decoration-line: underline;

  color: #7f909e;
}

.viewMore.center {
  margin-bottom: 0px;
  text-align: center;
}

.viewMore:hover {
  opacity: 0.6;
  cursor: pointer;
}

.roiContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 7em;
  height: 7em;
  position: relative;
}

.roiBackground {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
  width: 6em;
  height: 6em;
  margin-left: 10px;
}

.legend.roi {
  width: 144px;
}

.legendColumn {
  width: auto;
  margin-left: 0em;
}
