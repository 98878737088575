.genderChartContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  width: 0px;
}

.genderChartTitle {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  margin-top: 0px;

  text-align: center;
  text-transform: uppercase;

  color: #162235;
}

.genderChartColumnContainer {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.genderChartTopContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.genderCircleContainer {
  width: 76px;
  height: 76px;
  margin-left: 15px;
  margin-right: 15px;
}

.genderLabel {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  text-align: center;

  color: #45525e;
}

.nonBinaryCircle {
  width: 10px;
  height: 10px;
  background-color: #1e6834;
  border-radius: 50%;
  margin-right: 5px;
}

.nonBinaryContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
}

.nonBinaryContainer > span {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  display: flex;
  align-items: center;

  color: #45525e;
}
