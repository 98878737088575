#detailedScoreSVGContainer {
  align-self: stretch;
  flex-grow: 1;
  margin: 1em;
  overflow: visible;
}

.detailedChartAxisLabel {
  font-size: 14px;
  font-family: Source Sans Pro;
}

.fillPath {
  fill: linear-gradient(
      180.47deg,
      rgba(255, 255, 255, 0) 26.28%,
      #ffffff 99.37%
    ),
    linear-gradient(
      89.52deg,
      rgba(254, 190, 58, 0.5) 1.62%,
      rgba(173, 234, 164, 0.5) 99.85%
    );
}

.detailedChartLabel {
  align-self: start;
  margin-left: 2em;
  margin-top: 1em;
  color: #334050;
  font-size: 16px;
}

.detailedSvgLeftLabelContainer {
  align-self: stretch;
  flex-grow: 1;
  flex-direction: row;
  display: flex;
  align-items: stretch;
}

.detailedChartLeftLabel {
  transform: rotate(-90deg);
  margin-right: -40px;
  margin-left: -10px;
  color: #aec2ce;
  font-size: 14px;
  font-family: Source Sans Pro;
}

.detailedChartBottomLabel {
  color: #aec2ce;
  font-size: 14px;
  font-family: Source Sans Pro;
  padding-bottom: 1em;
}
